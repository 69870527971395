import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    year: '2024',
    raceType: 'president',
    party: 'GOP',
    state: 'IA',
    seat: '1',
    project: false
};   

export const app = createSlice({
    name: 'app',
    initialState,
    /**
     * Functions to change the app state
     */
    reducers: {   
        setYear: (state, {payload}) => {
            state.year = payload;
        },
        setRaceType: (state, {payload}) => {
            state.raceType = payload;
        },
        setParty: (state, {payload}) => {
            state.party = payload;
        },
        setState: (state, {payload}) => {
            state.state = payload;
        },
        setSeat: (state, {payload}) => {
            state.seat = payload;
        },
        setProject: (state, {payload}) => {
            state.project = payload;
        }
    }
});

export const {
    setYear,
    setRaceType,
    setParty,
    setState,
    setSeat,
    setProject
} = app.actions;
export default app.reducer;