import React from "react";
import {Navigate, Outlet} from "react-router";

const AuthorizedComponent = () => {
    if (localStorage.getItem('loggedin') !== '1') {
        return <Navigate to="/login"/>;
    }

    return <Outlet/>;
};

export default AuthorizedComponent;
