import { Box, Text } from "@mantine/core";

const TopBar = () => {

  return (
    <Box
      className={"topBar"}
      style={{
        fontFmaily: "inherit",
        width: "100%",
        display: "flex",
        position: "fixed",
        left: "0px",
        top: "0px",
        backgroundColor: "#fff",
        color: "#000",
        zIndex: "201",
        borderBottom: "solid 1px #ccc",
        //maxHeight: "10%"
      }}
    >
      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Text
          className={"topTitleText"}
          sx={{
            marginLeft: "1.2%",
            fontWeight: "bold",
            fontSize: "1.2rem",
            whiteSpace: "nowrap",
            lineHeight: 2.55
          }}
        >
          Chronos Backend
        </Text>
      </Box>
    </Box>
  );
};

export default TopBar;
