import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { ErrorHandlerMiddleware } from "./middlewares/errorHandler";
import appReducer from "./redux/app/slice";

const reducer = combineReducers({
    app: appReducer
});

const rootReducer = (state, action) => {
  if (action.type === "auth/logout") {
    return reducer(undefined, action);
  }
  return reducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      ErrorHandlerMiddleware,
    )
});