import React, { useCallback, useEffect, useState } from "react";
import { Box, Text, Select, Button, TextInput, Anchor, FileInput, Notification, rem } from "@mantine/core";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { appSelector } from "../../redux/app/selectors";
//import loader from "../../assets/img/loader.gif";
import {
  setProject
} from "../../redux/app/slice";
import ColorModal from "../Modals/ColorModal";
//import { StateNames } from "../../globals";
import { IconCheck } from '@tabler/icons-react';

const MainBoard = () => {
  const dispatch = useDispatch();
  const app = useSelector(appSelector);
  //const [projects, setProjects] = useState([{value: 1, label : "Project 1"}]);
  const [projects, setProjects] = useState([]);
  const [showColorModal, setShowColorModal] = useState(false);
  const [selectedColorType, setSelectedColorType] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxColor, setMaxColor] = useState("");
  const [minColor, setMinColor] = useState("");
  const [reload, setReload] = useState(false);
  const [years, setYears] = useState([]);
  const [rows, setRows] = useState([]);
  const [newYear, setNewYear] = useState(false);
  const [savedYears, setSavedYears] = useState(false);
  const [savedProject, setSavedProject] = useState(false);
  
  const openColorModal2 = useCallback(
    (colorType, color) => {
      setSelectedColorType(colorType);
      setSelectedColor(color);
      setShowColorModal(true);
    },
    []
  );

  const onSuccessColorModal = useCallback((color) => {
    if (selectedColorType === 'min_color')
      setMinColor(color);
    else
      setMaxColor(color);
    //setSelectedColor(color);
    //setReload(true);
  }, [selectedColorType]);

  const onCloseColorModal = useCallback(() => {
    setSelectedColor("");
    setShowColorModal(false);
  }, []);
  
  const changeProject = useCallback(
    (e) => {
      console.log(e);
      dispatch(setProject(e));
    },
    [dispatch]
  );
  
  const addNewProject = useCallback(
    () => {
      dispatch(setProject(0));
      setName("");
      setTitle("");
      setMaxValue("");
      setMinValue("");
      setMaxColor("");
      setMinColor("");
      setYears([]);
    },
    [dispatch]
  );
  
  const loadProjectYears = useCallback(
    (project_id) => {
        //load project years
        let url =
          process.env.REACT_APP_BASE_URL +
          "/api/projectYears/" + project_id;
          console.log(url);
        axios.get(url).then(function (response) {
          console.log(response);
          if (response.data && response.data.data) {
            setYears(response.data.data);
            
            const el = document.getElementsByClassName('mantine-CloseButton-root');
            for (let i=0;i<el.length; i++) {
                el[i].click();
            }
          }
        });
    },
    []
  );
  
  const loadProjects = useCallback(
    () => {
        //get all projects
        let url =
          process.env.REACT_APP_BASE_URL +
          "/api/projects/?user_id=" + localStorage.getItem('user_id');
        console.log(url);

        axios.get(url).then(function (response) {
          console.log(response);
          if (response.data && response.data.data) {
            let p = [];
            let latest = 0;
            for (const pj of response.data.data) {
              let d = {}
              d.value = pj.id;
              d.label = pj.name;
              p.push(d);
              if (pj.id > latest)
                latest = pj.id;
            }
            
            console.log(p);
            setProjects(p);
            //set latest project        
            dispatch(setProject(latest));  

            loadProjectYears(latest);
          }
        });
    },
    [dispatch, loadProjectYears]
  );
  
  const saveProject = useCallback(
    () => {
      //save project
      let url = '';
      let new_project = false;
      if (app.project === 0) {
        url =
        process.env.REACT_APP_BASE_URL +
        "/api/saveProject";
        new_project = true;
      }
      else {
        url =
        process.env.REACT_APP_BASE_URL +
        "/api/saveProject/" + app.project;
      }
      
      const payload = {name: name, title: title, maxValue: maxValue, minValue: minValue, maxColor: maxColor, minColor: minColor, user_id: localStorage.getItem('user_id')};
      console.log(payload);
      axios.post(url, payload).then(function (response) {
        if (new_project) {
          loadProjects();
        }
        else {
          setReload(true);
        }
        
        setSavedProject(true);
        setTimeout(() => { setSavedProject(false); }, 3000);
      });      
    },
    [loadProjects, app.project, name, title, maxValue, minValue, maxColor, minColor]
  );
  
  const deleteProject = useCallback(() => {
    if (window.confirm('Are you sure to delete this project?')) {
      let url = process.env.REACT_APP_BASE_URL +
        "/api/deleteProject/" + app.project;
      axios.post(url, {}).then(function (response) {
        loadProjects();
      });
    }
  }, [app.project, loadProjects]);
  
  const deleteFile = useCallback((type, year_id) => {
    if (window.confirm('Are you sure to delete this file?')) {
      let url = process.env.REACT_APP_BASE_URL +
        "/api/deleteFile/" + app.project;
      axios.post(url, {type:type, year_id:year_id}).then(function (response) {
        loadProjects();
      });
    }
  }, [app.project, loadProjects]);
  
  const deleteYear = useCallback((year_id) => {
    if (window.confirm('Are you sure to delete this year?')) {
      let url = process.env.REACT_APP_BASE_URL +
        "/api/deleteYear/" + app.project;
      axios.post(url, {year_id:year_id}).then(function (response) {
        loadProjects();
      });
    }
  }, [app.project, loadProjects]);
  
  const deleteNewYear = useCallback(() => {
    setNewYear(false);
  }, []);
  
  const saveYears = useCallback(
    () => {
      let url = process.env.REACT_APP_BASE_URL +
        "/api/saveYears/" + app.project;  
        
      const formData = new FormData();
      
      let ylength = years.length;
      if (ylength === 0) {
        ylength = 1;
      }
      else if (newYear) {
        ylength = ylength + 1;
      }
      
      formData.append("length", ylength);
      for (let i = 0; i < ylength; i++) {
         console.log('yyyyy');
         console.log(document.getElementById('year_id_' + i).value);
         console.log(document.getElementById('year_' + i).value);
         console.log(document.getElementById('state_csv_' + i).parentElement.nextElementSibling.files);
         
         if (!document.getElementById('year_' + i).value) {
            alert('Year must be entered.');
            return false;
         }
         
         formData.append("year_id_" + i, document.getElementById('year_id_' + i).value);
         formData.append("year_" + i, document.getElementById('year_' + i).value);
         if (document.getElementById('state_csv_' + i).parentElement.nextElementSibling.files.length > 0) {
           formData.append("state_csv_" + i, document.getElementById('state_csv_' + i).parentElement.nextElementSibling.files[0]);
         }
         if (document.getElementById('district_csv_' + i).parentElement.nextElementSibling.files.length > 0) {
           formData.append("district_csv_" + i, document.getElementById('district_csv_' + i).parentElement.nextElementSibling.files[0]);
         }
         if (document.getElementById('municipal_csv_' + i).parentElement.nextElementSibling.files.length > 0) {
           formData.append("municipal_csv_" + i, document.getElementById('municipal_csv_' + i).parentElement.nextElementSibling.files[0]);
         }
      }
      
      axios.post(url, formData).then(function (response) {
        setNewYear(false);
        loadProjectYears(app.project);      
        setSavedYears(true);
        setTimeout(() => { setSavedYears(false); }, 3000);
      });
    },
    [app.project, years, loadProjectYears, newYear]
  );
  
  const addNewYear = useCallback(
    () => {
      setNewYear(true);
    },
    []
  );
   
  useEffect(() => {
    console.log('init');
    loadProjects();
  }, [loadProjects]);
  
  useEffect(() => {    
    if (reload) {
        console.log('reload');
        setReload(false);
        //get all projects
        let url =
          process.env.REACT_APP_BASE_URL +
          "/api/projects";
        console.log(url);

        axios.get(url).then(function (response) {
          if (response.data && response.data.data) {
            let p = [];
            let latest = 0;
            for (const pj of response.data.data) {
              let d = {}
              d.value = pj.id;
              d.label = pj.name;
              p.push(d);
              if (pj.id > latest)
                latest = pj.id;
            }
            setProjects(p);
          }
        });
    }
  }, [reload]);
  
  useEffect(() => {    
    if (app.project) {
      //get project by id
      let url =
        process.env.REACT_APP_BASE_URL +
        "/api/getProject/" + app.project;
      console.log(url);
        
      axios.get(url).then(function (response) {
        console.log(response);
        if (response.data && response.data.data && response.data.data[0]) {
          setName(response.data.data[0].name);
          setTitle(response.data.data[0].title);
          setMaxValue(response.data.data[0].max_value);
          setMinValue(response.data.data[0].min_value);
          setMaxColor(response.data.data[0].max_color);
          setMinColor(response.data.data[0].min_color);
        }
      });
      
      loadProjectYears(app.project);
    }
    else {
      setName("");
      setTitle("");
      setMaxValue("");
      setMinValue("");
      setMaxColor("");
      setMinColor("");
    }
  }, [app.project, loadProjectYears]);
  
  useEffect(() => {
    //const storages = { ...localStorage };
    //console.log(storages);
    console.log('app:');
    console.log(app);
  }, [app]);
  
  useEffect(() => {
    const yrows = years
      .map((el, k) => (
        <Box key={'box_' + k} style={{ marginTop: "20px", paddingTop: "20px", borderTop: "solid 1px #999999" }}>
          <Box style={{display: "flex", width:"100%"}}>
            <Text
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "15px",
                fontSize: "15px"
              }}
            >
              <b>Year:</b>
            </Text>
            <TextInput
              className={"yearInput"}
              placeholder="Year"
              style={{width:"200px"}}
              defaultValue={el.year}
              id={'year_' + k}
              key={el.year}
            />
            <Anchor href="javscript:void(0);" onClick={() => deleteYear(el.id)} style={{color:"red", marginTop:"7px", marginLeft:"25px", textDecoration:"underline"}}>Delete</Anchor>
            <input type="hidden" id={'year_id_' + k} value={el.id} />
          </Box>
          <Box style={{dispaly:"flex", width:"100%", marginTop:"20px"}}>
            <FileInput
              label="State CSV (Format: ID_ESTADO, VALUE):"
              placeholder="Click to select"
              accept=".csv"
              id={'state_csv_' + k}
              style={{ width:"500px" }}
              clearable 
            />
            {el.state_csv &&
            (<div style={{marginTop:'5px'}}>
              <Anchor href={process.env.REACT_APP_CHRONOS_URL + '/csv/' + el.project_id + '_' + el.id + '_state.csv'} style={{paddingTop:'10px', fontSize:"15px"}}>{app.project}_{el.id}_state.csv</Anchor>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{color:"red", cursor:"pointer", fontFamily:"Arial", fontWeight:"Bold"}} onClick={() => deleteFile('state', el.id)}>X</span>
            </div>)}
          </Box>
          <Box style={{dispaly:"flex", width:"100%", marginTop:"20px"}}>
            <FileInput
              label="District CSV (Format: ID_ESTADO, ID_DISTRITO_FEDERAL, VALUE):"
              placeholder="Click to select"
              accept=".csv"
              id={'district_csv_' + k}
              style={{ width:"500px" }}
              clearable 
            />
            {el.district_csv &&
            (<div style={{marginTop:'5px'}}>
              <Anchor href={process.env.REACT_APP_CHRONOS_URL + '/csv/' + el.project_id + '_' + el.id + '_district.csv'} style={{paddingTop:'10px', fontSize:"15px"}}>{app.project}_{el.id}_district.csv</Anchor>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{color:"red", cursor:"pointer", fontFamily:"Arial", fontWeight:"Bold"}} onClick={() => deleteFile('district', el.id)}>X</span>
            </div>)}
          </Box>
          <Box style={{dispaly:"flex", width:"100%", marginTop:"20px"}}>
            <FileInput
              label="Municipal CSV (Format: ID_ESTADO, ID_MUNICIPIO, VALUE):"
              placeholder="Click to select"
              accept=".csv"
              id={'municipal_csv_' + k}
              style={{ width:"500px" }}
              clearable 
            />
            {el.municipal_csv &&
            (<div style={{marginTop:'5px'}}>
              <Anchor href={process.env.REACT_APP_CHRONOS_URL + '/csv/' + el.project_id + '_' + el.id + '_municipal.csv'} style={{paddingTop:'10px', fontSize:"15px"}}>{app.project}_{el.id}_municipal.csv</Anchor>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{color:"red", cursor:"pointer", fontFamily:"Arial", fontWeight:"Bold"}} onClick={() => deleteFile('municipal', el.id)}>X</span>
            </div>)}
          </Box>
        </Box>
      ));
    setRows(yrows);
  }, [years, app.project, deleteFile, deleteYear]);
  
  let m = years.length;
  
  const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;

  return (
    <Box
      className={"MainBoard"}
      style={{
        width: "100%",
        fontSize: "14px",
        padding: "20px 20px"
      }}
    >
      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Box style={{ display: "flex" }}>
          <Text
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "15px",
              fontSize: "15px"
            }}
          >
            <b>Project:</b>
          </Text>
          <Select
            placeholder="New Project"
            data={projects}
            defaultValue={app.project}
            value={app.project}
            style={{ marginRight: "25px" }}
            onChange={(e) => changeProject(e)}
            searchable
          />          
          {app.project > 0 && <Anchor href="javscript:void(0);" onClick={() => deleteProject()} style={{color:"red", marginTop:"7px", marginRight:"25px", textDecoration:"underline"}}>Delete</Anchor>}
          <Button onClick={() => addNewProject()}>+ New Project</Button>
        </Box>
        {(app.project > 0) && 
        <Box style={{ display: "flex", marginTop: "25px" }}>
          <Text
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "15px",
              fontSize: "15px"
            }}
          >
            <b>URL:</b>
          </Text><Anchor href={process.env.REACT_APP_CHRONOS_URL + '/?project=' + app.project} target="_blank" style={{ fontSize: "15px" }}>{process.env.REACT_APP_CHRONOS_URL}/?project={app.project}</Anchor>
        </Box>}        
        <Box style={{ display: "flex", marginTop: "20px" }}>
          <TextInput
            label="Name:"
            placeholder="Project Name"
            style={{width:"500px"}}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />          
        </Box>
        <Box style={{ display: "flex", marginTop: "10px" }}>
          <TextInput
            label="Title:"
            placeholder="Top Title"
            style={{width:"500px"}}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Box>
        <Box style={{ display: "flex", marginTop: "10px" }}>
          <TextInput
            label="Max. Color:"
            placeholder="Click to select"
            style={{width:"130px"}}
            value={maxColor}
            onClick={(e) =>
                openColorModal2(
                  "max_color",
                  maxColor
                )
            }
            onChange={(e) => {}}
          />
          <TextInput
            label="Max. Value:"
            placeholder="e.g. 10000 (Optional)"
            style={{width:"170px", marginLeft: "12px"}}
            value={maxValue}
            onChange={(e) => { setMaxValue(e.target.value); }}
          />          
          <Text style={{color:"#1c7ed6", marginLeft:"10px", marginTop:"29px"}}><i>*It will get from the CSV if you leave it blank.</i></Text>
        </Box>
        <Box style={{ display: "flex", marginTop: "10px" }}>
          <TextInput
            label="Min. Color:"
            placeholder="Click to select"
            style={{width:"130px"}}
            value={minColor}
            onClick={(e) =>
                openColorModal2(
                  "min_color",
                  minColor
                )
            }
            onChange={(e) => {}}
          />
          <TextInput
            label="Min. Value:"
            placeholder="e.g. 0 (Optional)"
            style={{width:"170px", marginLeft: "12px"}}
            value={minValue}
            onChange={(e) => { setMinValue(e.target.value); }}
          />        
          <Text style={{color:"#1c7ed6", marginLeft:"10px", marginTop:"29px"}}><i>*It will get from the CSV if you leave it blank.</i></Text>          
        </Box>
        <Box style={{ display: "flex", marginTop: "25px", marginBottom: "1px" }}>
          <Button onClick={() => saveProject()}>Save Project</Button>
        </Box>
        {rows}
        {app.project > 0 && (years.length === 0 || newYear) && (<Box key={'box_' + m} style={{ marginTop: "20px", paddingTop: "15px", borderTop: "solid 1px #999999" }}>
          <Box style={{display: "flex", width:"100%"}}>
            <Text
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "15px",
                fontSize: "15px"
              }}
            >
              <b>Year:</b>
            </Text>
            <TextInput
              className={"yearInput"}
              placeholder="Year"
              style={{width:"200px"}}
              defaultValue={''}
              id={'year_' + m}
              key={''}
            />
            <Anchor href="javscript:void(0);" onClick={() => deleteNewYear()} style={{color:"red", marginTop:"7px", marginLeft:"25px", textDecoration:"underline"}}>Delete</Anchor>
            <input type="hidden" id={'year_id_' + m} value={0} />
          </Box>
          <Box style={{dispaly:"flex", width:"100%", marginTop:"20px"}}>
            <FileInput
              label="State CSV (Format: ID_ESTADO, VALUE):"
              placeholder="Click to select"
              accept=".csv"
              id={'state_csv_' + m}
              style={{ width:"500px" }}
              clearable 
            />
          </Box>
          <Box style={{dispaly:"flex", width:"100%", marginTop:"20px"}}>
            <FileInput
              label="District CSV (Format: ID_ESTADO, ID_DISTRITO_FEDERAL, VALUE):"
              placeholder="Click to select"
              accept=".csv"
              id={'district_csv_' + m}
              style={{ width:"500px" }}
              clearable 
            />
          </Box>
          <Box style={{dispaly:"flex", width:"100%", marginTop:"20px"}}>
            <FileInput
              label="Municipal CSV (Format: ID_ESTADO, ID_MUNICIPIO, VALUE):"
              placeholder="Click to select"
              accept=".csv"
              id={'municipal_csv_' + m}
              style={{ width:"500px" }}
              clearable 
            />
          </Box>
        </Box>)}
        {app.project > 0 && (<Box style={{ display: "flex", marginTop: "25px", marginBottom: "100px" }}>
          <Button onClick={() => saveYears()}>Save Year(s)</Button>
          {m > 0 && !newYear && (<Button onClick={() => addNewYear()} style={{marginLeft:"270px"}}>+ New Year</Button>)}
        </Box>)}
      </Box>

      <ColorModal
        open={showColorModal}
        onClose={onCloseColorModal}
        selectedColorType={selectedColorType}
        selectedColor={selectedColor}
        onSuccess={onSuccessColorModal}
      />
      
      {savedYears && (<Notification icon={checkIcon} color="teal" title="Years saved successfully!" mt="md" style={{position:"fixed", top:"35px", right:"20px", border:"solid 1px #aaaaaa", width:"320px"}}>
      </Notification>)}
      
      {savedProject && (<Notification icon={checkIcon} color="teal" title="Project saved successfully!" mt="md" style={{position:"fixed", top:"35px", right:"20px", border:"solid 1px #aaaaaa", width:"320px"}}>
      </Notification>)}
    </Box>
  );
};

export default MainBoard;
