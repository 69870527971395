import React from 'react';

import {Navigate} from 'react-router-dom';

import {Box, Container, Text, TextInput, Button} from "@mantine/core";

import {loginSchema} from '../../yupSchemas/shemas';

import style from './LogIn.module.css';
import variables from "../../_variables.scss";
import {useFormik} from "formik";
import axios from 'axios';

const LogIn = () => {
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: loginSchema,
        onSubmit: (values, {setErrors, setSubmitting}) => {
            setSubmitting(true);       
            axios.get(process.env.REACT_APP_BASE_URL + '/api/auth/backend_login?email=' + values.email + '&password=' + values.password).then(function (response) {
                setSubmitting(false);
                if (response.data && response.data.status && response.data.status === 'ok') {
                    localStorage.setItem('loggedin', 1);
                    localStorage.setItem('user_id', response.data.user_id);
                }
                else
                    setErrors({password: "Invalid credentials."});
            });
        }
    });
    
    //localStorage.setItem('loggedin', 0);
    if (localStorage.getItem('loggedin') === '1') {
        return <Navigate to="/"/>;
    }

    return (
        <Container id={'login'} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            width: '48%'
        }}>
            <Container sx={{
                display: 'flex',
                justifyContent: 'center',
                bgcolor: '#ffffff',
                border: '2px solid #ffffff',
                textAlign: 'center',
                color: '#414343',
                width: '100%'
            }}>
                <Box className={'loginBox'} sx={{width: '100%', maxWidth: '380px'}}>
                    <Box sx={{marginTop: '32px', marginBottom: '48px'}}>
                        <Text className={'title'} sx={{fontWeight:'bold', fontSize:'42px'}}>Chronos Backend</Text>
                    </Box>
                    <form onSubmit={formik.handleSubmit}>
                        <Box sx={{marginBottom: '24px', paddingTop: '16px'}}>
                            <TextInput sx={{width: '100%', textAlign:'left'}} radius="unset"
                                placeholder={'Your Email'}
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && formik.errors.email}/>
                        </Box>
                        <Box sx={{marginBottom: '40px'}}>
                            <TextInput sx={{width: '100%', textAlign:'left'}} radius="unset"
                                type="password"
                                placeholder={'Your Password'}
                                name="password"
                                autoComplete="off"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && formik.errors.password}/>

                        </Box>
                        <Button type="submit"
                                color="primary" variant="contained"
                                className={style.loginButton}
                                sx={{width: '150px', height: 40, marginBottom: '56px', backgroundColor:variables.primaryHex, color:'#fff'}}>
                            Login
                        </Button>
                    </form>
                    <Box sx={{marginBottom: '40px'}} className={style.forgotBox}>
                    </Box>

                </Box>
            </Container>
        </Container>);
};

export default LogIn;
