import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { store } from "./store";
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider } from "@mantine/core";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MantineProvider
              theme={{
                fontFamily: "'Libre Franklin', sans-serif",
                // Override any other properties from default theme
                spacing: {
                  xs: "1rem",
                  sm: "1.2rem",
                  md: "1.8rem",
                  lg: "2.2rem",
                  xl: "2.8rem"
                }
              }}
            >
        <App />
      </MantineProvider>
    </BrowserRouter>
  </Provider>
);
