import { Navbar, ScrollArea, createStyles, rem } from "@mantine/core";
import { LinksGroup } from "./NavbarLinksGroup";

const menuData = [
  {
    label: "Projects",
    key: "Projects",
    initiallyOpened: true,
    /*links: [
      { key: "2021", label: "2021", year: "2021", raceType: "cong" },
      { key: "2018", label: "2018", year: "2018", raceType: "pres" },
      { key: "2015", label: "2015", year: "2015", raceType: "cong" },
      { key: "2012", label: "2012", year: "2012", raceType: "pres" },
      { key: "2009", label: "2009", year: "2009", raceType: "cong" },
      { key: "2006", label: "2006", year: "2006", raceType: "pres" }
    ]*/
  },
  {
    label: "Logout",
    key: "Logout"
  }
];

const useStyles = createStyles((theme) => ({
  navbar: {
    //backgroundColor:
    //theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    backgroundColor: "rgba(237,237,237,0.5)",
    backdropFilter: "blur(5px)",
    paddingBottom: 0,
    marginTop: "0px",
    overflowX: "hidden"
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`
  },

  links: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -0.75)`
  },

  linksInner: {
    //paddingTop: theme.spacing.md,
    paddingTop: "15px",
    paddingBottom: theme.spacing.xl
  },

  footer: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`
  }
}));

export default function NavbarNested() {
  const { classes } = useStyles();

  //const app = useSelector(appSelector);

  const links = menuData.map((item) =>
    (
      <LinksGroup
        {...item}
        itemKey={item.key}
      />
    )
  );

  return (
    <Navbar
      width={{ sm: 250 }}
      p="sm"
      className={classes.navbar}
      sx={{
        right: "auto",
        left: "0px"
      }}
    >
      <Navbar.Section className={classes.header}></Navbar.Section>

      <Navbar.Section grow className={classes.links} component={ScrollArea}>
        {links && <div className={classes.linksInner}>{links}</div>}
      </Navbar.Section>

      <Navbar.Section className={classes.footer}></Navbar.Section>
    </Navbar>
  );
}
