import { Box, AppShell, Navbar, Header } from "@mantine/core";
import NavbarNested from "./NavBar/Navbar";
import TopBar from "./TopBar/TopBar";
import MainBoard from "./MainBoard/MainBoard";

const Home = () => {
  return (
    <AppShell
      padding={0}
      layout="alt"
      navbar={
        <Navbar
          width={{ base: 300 }}
          sx={{
            backgroundColor: "transparent",
            right: "auto",
            left: "0px"
          }}
        >
          <NavbarNested />
        </Navbar>
      }
      header={
        <Header height={70} p="xs">
          {/* Header content */}
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0]
        }
      })}
    >
      <TopBar />
      <Box
        style={{
          width: "92.5%",
          height: "calc(100vh - 49px)",
          display: "flex",
          position: "absolute",
          left: "7.5%",
          top: 49,
        }}
      >
        <MainBoard />
      </Box>
    </AppShell>
  );
};
    
export default Home;