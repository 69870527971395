import { useState } from "react";
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  Text,
  UnstyledButton,
  Switch,
  createStyles,
  rem
} from "@mantine/core";
//import { appSelector } from "../redux/app/selectors";
//import { logout } from "../redux/auth/slice";
//import { useLogOutMutation } from "../api/auth";
//import { useSignOut } from "react-auth-kit";
import triangle from "../../assets/img/triangle.png";

const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: "block",
    width: "100%",
    borderLeft: "25px solid #000",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    paddingTop: "7px",
    paddingBottom: "7px",
    marginBottom: "2px",
    backgroundColor: "rgba(188,188,188,0.3)",
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,

    "&:hover": {
      /*backgroundColor: 
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],*/
      backgroundColor: "rgba(61,61,61,0.3)",
      color: theme.colorScheme === "dark" ? theme.white : theme.black
    }
  },
  link: {
    fontWeight: 500,
    display: "block",
    textDecoration: "none",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    paddingLeft: rem(31),
    marginLeft: rem(44),
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    borderLeft: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black
    }
  },
  selected: {
    /*backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.gray[2],*/
    backgroundColor: "rgba(61,61,61,0.3) !important",
    backgroundImage: "url(" + triangle + ")",
    backgroundSize: "6% 100%",
    backgroundRepeat: "no-repeat",
    borderBottom: "solid 1px #222222",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    "&:after": {
      //content: '"<<"',
      //paddingLeft: 10
    },
    fontWeight: "bold"
  },
  opened: {
    backgroundColor: "rgba(61,61,61,0.3)"
  },
  sublink: {
    //paddingLeft: "3.0rem",
    fontSize: "0.75rem",
    backgroundImage: "none !important",
    marginLeft: "16px !important",
    marginRight: "16px !important"
  },
  chevron: {
    transition: "transform 200ms ease"
  },
  toggle: {
    flexBasis: "100%",
    marginTop: 6,
    marginLeft: "1.6rem"
  }
}));

export function LinksGroup({
  icon: Icon,
  label,
  itemKey,
  initiallyOpened,
  links,
  toggle,
}) {
  const { classes /*, theme*/ } = useStyles();
  const hasLinks = Array.isArray(links);

  const [opened, setOpened] = useState(initiallyOpened || false);

  //const ChevronIcon = theme.dir === "ltr" ? IconChevronRight : IconChevronLeft;

  //const dispatch = useDispatch();
  //const app = useSelector(appSelector);
  let [toggleYear, setToggleYear] = useState(true);

  //const [logOut] = useLogOutMutation();
  //const signOut = useSignOut();
  const year = 2022;
  const raceType = 'governor';

  const items = (hasLinks ? links : []).map((link) => {
    const yearLink = (
      <Text
        component="a"
        className={`${classes.link} ${
          year === link.year &&
          raceType === link.raceType /* && party === null*/
            ? classes.selected
            : ""
        }`}
        href={"/"}
        onClick={(event) => {
          event.preventDefault();  

          if (year !== link.year || raceType !== link.raceType) {
            //dispatch(setShowRaceboard(false));
            //dispatch(setClickedMarker(false));
            toggleYear = false;
          }
          setToggleYear(!toggleYear);
        }}
      >
        {link.label}
      </Text>
    );

    return (
      <div key={link.key}>
        {yearLink}
      </div>
    );
  });

  const getStoreValue = (itemKey) => {
    let storeVal = null;

    switch (itemKey) {
      case "ForceMunicipals":
        //storeVal = forceMun;
        break;
      case "ForceDistricts":
        //storeVal = forceDis;
        break;
      case "VoteCircles":
        //storeVal = voteCircle;
        break;
      case "RaceChart":
        //storeVal = app.showRaceChart;
        break;
      case "Telestrator":
        //storeVal = app.showTelestrator;
        break;
      case "Reverse":
        //storeVal = app.reverse;
        break;
      default:
        break;
    }

    return storeVal;
  };

  const setStoreValue = (itemKey, value) => {
    switch (itemKey) {
      case "ForceMunicipals":
        //dispatch(updateForceMun(value));
        break;
      case "ForceDistricts":
        //dispatch(updateForceDis(value));
        break;
      case "VoteCircles":
        //dispatch(updateVoteCircle(value));
        break;
      case "RaceChart":
        //dispatch(setShowRaceChart(value));
        break;
      case "Telestrator":
        //dispatch(setShowTelestrator(value));
        break;
      case "Reverse":
        //dispatch(setReverse(value));
        break;
      default:
        break;
    }
  };

  const itemClicked = () => {
    if (links) setOpened((o) => !o);
    else {
      if (itemKey === "Logout") {
        localStorage.setItem('loggedin', 0);
        document.location.href = '/login';
      } else if (itemKey === "AddBookmark") {
        //dispatch(setOpenModal("MODAL_CREATE_BOOKMARK"));
      }
    }
  };

  return (
    <div>
      <UnstyledButton
        onClick={() => itemClicked()}
        className={`${classes.control} ${opened ? classes.opened : ""}`}
        id={itemKey + "_menubtn"}
      >
        <Group position="apart" spacing={0}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {Icon && (
              <ThemeIcon variant="light" size={30}>
                <Icon size="1.1rem" />
              </ThemeIcon>
            )}
            <Box ml="md">{label}</Box>
          </Box>
          {toggle && (
            <div className={classes.toggle}>
              <Switch
                size="md"
                onLabel={"On"}
                offLabel={"Off"}
                checked={getStoreValue(itemKey)}
                onChange={(event) =>
                  setStoreValue(itemKey, event.target.checked)
                }
              />
            </div>
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </div>
  );
}
