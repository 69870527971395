import React, {useEffect, useState} from 'react';
//import { useSelector } from "react-redux";
import {Box, Modal, Title, TextInput, Button, ColorPicker} from "@mantine/core";
import {useFormik} from 'formik';
//import axios from 'axios';
import {colorSchema} from '../../yupSchemas/shemas';
//import { appSelector } from "../../redux/app/selectors";

const ColorModal = ({open, onClose, selectedColorType, selectedColor, selectedFirst, selectedLast, selectedCandID, onSuccess}) => {    
    //const app = useSelector(appSelector);
    const [colorValue, setColorValue] = useState(selectedColor);

    const {
        values, touched, errors, handleChange, handleSubmit, setValues
    } = useFormik({
        initialValues: {
            color: selectedColor
        },
        validationSchema: colorSchema,
        onSubmit: (values, {setSubmitting, resetForm}) => {
            setSubmitting(true);                
            //resetForm({values: ''});
            onSuccess(values.color);
            onClose();           
        },
    });
    
    useEffect(() => {
        setValues(prev => ({...prev, color: selectedColor}));
        setColorValue(selectedColor);
    }, [setValues, selectedColor])
    
    
    useEffect(() => {
        setValues(prev => ({...prev, color: colorValue}));
    }, [setValues, colorValue])    
    
    return (
        <Modal
            opened={open}
            onClose={onClose}
            centered
            size="38%"
        >
            <Box sx={{height: '100%'}}>
                <Title sx={{marginBottom: '20px', fontWeight: 'normal', fontSize: 25, textAlign: 'center'}}>Choose Color</Title>
                <form onSubmit={handleSubmit}>
                    <Box style={{display:'flex', justifyContent: 'center', position: 'relative'}}>
                        <ColorPicker value={colorValue} onChange={setColorValue} />
                        <Box style={{position: 'absolute', left: 'calc(50% + 115px)', top:'28%', width:'50px', height:'50px', borderRadius:'25px', backgroundColor:colorValue}}></Box>
                    </Box>
                    <Box>
                        <TextInput sx={{width: '100%'}} radius="unset"
                            placeholder={'e.g. #FF0000'}
                            label="Color Hex Code"
                            name={'color'}
                            value={values.color}
                            onChange={(e) => {
                               const reg = /^#([0-9a-f]{3}){1,2}$/i;
                               if (reg.test(e.target.value)) {
                                   console.log(e.target.value);
                                   setColorValue(e.target.value);
                               }
                               handleChange(e);
                            }}
                            error={selectedColor && touched.color && errors.color}/>
                    </Box>
                    <Box sx={{mt: 10, display: 'flex', justifyContent: 'center', width: '100%', marginTop: '30px'}}>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="submit" style={{marginLeft: '100px'}}>Save</Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
};

export default ColorModal;