import { Route, Routes } from "react-router-dom";
import Login from "./components/Auth/LogIn";
import Home from "./components/Home";
import AuthorizedComponent from "./components/AuthorizedComponent";

function App() {
  return (
    <Routes>     
      <Route path="/" element={<AuthorizedComponent />}>
        <Route path="/" element={<Home />} />
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default App;
